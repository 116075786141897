/* eslint-disable class-methods-use-this */

import Service from './Service';

class TransformerService extends Service {
  constructor() {
    super('transformers');
  }

  getFieldsTableTransformers() {
    return [
      {
        key: 'description',
        label: 'Descrição',
        tdClass: 'text-center text-nowrap',
        thClass: 'text-center text-nowrap',
      },
      {
        key: 'kva',
        label: 'Potência (KVA)',
        tdClass: 'text-center text-nowrap',
        thClass: 'text-center text-nowrap',
      },
      {
        key: 'price',
        label: 'Custo ',
        tdClass: 'text-center text-nowrap',
        thClass: 'text-center text-nowrap',
      },
      {
        key: 'actions',
        label: 'Ações',
        tdClass: 'text-center text-nowrap',
        thClass: 'text-center text-nowrap',
      },
    ];
  }
}

const transformerService = new TransformerService();
export default transformerService;

<template>
    <div>
      <b-row class="card mt-5">
        <div class="w-100">
          <h5 class="title-card">
            <i class="fas fa-microchip"></i> Dados do transformador
          </h5>
        </div>
        <div class="w-100 p-3">
          <b-form>
            <b-row>
              <b-col lg="4">
                <b-form-group label="Descrição do transformador">
                  <b-form-input
                    :class="{ 'form-group--error': $v.payload.description.$error }"
                      v-model="$v.payload.description.$model"
                      :state="$v.payload.description.$dirty ?
                      !$v.payload.description.$error : null"
                  />
                  <b-form-invalid-feedback>
                    Preenchimento obrigatório
                  </b-form-invalid-feedback>
                </b-form-group>
              </b-col>

              <b-col lg="2">
                <b-form-group label="Potência (KVA)">
                  <b-form-input
                    :class="{ 'form-group--error': $v.payload.kva.$error }"
                    v-model="$v.payload.kva.$model"
                    :state="$v.payload.kva.$dirty ?
                    !$v.payload.kva.$error : null"
                  />
                  <b-form-invalid-feedback>
                    Preenchimento obrigatório
                  </b-form-invalid-feedback>
                </b-form-group>
              </b-col>

              <b-col lg="3">
                <b-form-group label="Preço custo">
                  <money
                    class="form-control"
                    :class="{ 'form-group--error': $v.payload.price.$error }"
                    v-model="$v.payload.price.$model"
                    :state="$v.payload.price.$dirty ?
                    !$v.payload.price.$error : null"
                    v-bind="money"
                  />
                  <b-form-invalid-feedback>
                    Preenchimento obrigatório
                  </b-form-invalid-feedback>
                </b-form-group>
              </b-col>

              <div class="w-100 text-left mt-3">
                    <b-button class="ml-3" variant="success" @click="save(false)"><i class="fas fa-save"></i> Salvar</b-button>
                    <b-button class="ml-3" variant="primary" v-if="!id" @click="save(true)">
                      <i class="fas fa-save"></i>  Salvar e continuar
                    </b-button>
                </div>
            </b-row>
          </b-form>
        </div>
      </b-row>
    </div>
  </template>

<script>
import { required } from 'vuelidate/lib/validators';
import MessagesMixin from '@/mixins/messages-mixin';
import QuestionMixin from '@/mixins/question-mixin';
import ErrorMixin from '@/mixins/error-mixin';
import { Money } from 'v-money';
import TransformerService from '../../../Services/TransformerService';

export default {
  props: ['id'],
  components: {
    Money,
  },
  mixins: [MessagesMixin, QuestionMixin, ErrorMixin],
  data() {
    return {
      money: {
        decimal: ',',
        thousands: '.',
        prefix: 'R$ ',
        precision: 2,
        masked: false, /* doesn't work with directive */
      },
      payload: {
        description: '',
        kva: '',
        price: '',
      },
    };
  },
  validations: {
    payload: {
      description: { required },
      kva: { required },
      price: { required },
    },
  },
  methods: {
    async getTransformer() {
      try {
        const { data } = await TransformerService.get(this.id);
        this.payload = data;
      } catch (error) {
        this.error(error);
      }
    },
    resetForm() {
      this.payload = {
        description: '',
        kva: '',
        price: '',
      };
      this.$v.payload.$reset();
    },
    async save(continueCreate) {
      this.$v.payload.$touch();
      if (!this.$v.payload.$invalid) {
        try {
          const { data } = await this.id ? TransformerService.edit(this.payload) : TransformerService.save(this.payload);
          console.log(data);

          if (this.id) {
            this.messageSuccess('Transformador editado com sucesso!');
          } else {
            this.messageSuccess('Transformador criado com sucesso!');
          }

          if (continueCreate && !this.id) {
            this.resetForm();
          } else {
            this.$router.push({ name: 'transformers' });
          }
        } catch (error) {
          console.log(error);
        }
      }
    },
  },
  created() {
    if (this.id) {
      this.getTransformer();
    }
  },
};
</script>
